import 'normalize.css';
import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './src/index.css';
import $ from 'jquery';
import SplitText from 'vendor/SplitText';
import DrawSVG from 'vendor/DrawSVGPlugin';
import MorphSVGPlugin from 'vendor/MorphSVGPlugin';

window.$ = $;
window.jQuery = $;
window.SplitText = SplitText;
window.DrawSVG = DrawSVG;
window.MorphSVGPlugin = MorphSVGPlugin; 
